import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/';
import DOMPurify from 'dompurify';
import { FaCopy } from 'react-icons/fa';
const { handleCopyClick } = require('../utils/format_response');

const parseTextToHtml = (text, styles) => {
  const lines = text.split('\n');

  const parseLinesToNodes = (lines) => {
    const rootNodes = [];
    let listStack = []; // Stack to manage list nodes
    let insideCodeBlock = false;
    let codeBlock = '';
    let codeLanguage = '';
    let buffer = '';
    let currentDepth = 0;

    lines.forEach((line, index) => {
      const leadingSpaces = line.match(/^(\s*)/)[1].length;
      const depth = Math.floor(leadingSpaces / 2); // Each indent level is 2 spaces
      const trimmedLine = line.trim();

      if (insideCodeBlock) {
        if (trimmedLine.startsWith('```')) {
          // End of code block
          insideCodeBlock = false;

          const codeNode = {
            type: 'code',
            language: codeLanguage || 'plaintext',
            content: codeBlock.trim(),
            depth: currentDepth,
          };

          // Add codeNode to the appropriate parent
          if (listStack.length > 0) {
            const parentNode = listStack[listStack.length - 1];
            parentNode.children.push(codeNode);
          } else {
            rootNodes.push(codeNode);
          }

          codeBlock = '';
          codeLanguage = '';
        } else {
          codeBlock += line + '\n';
        }
      } else if (trimmedLine.startsWith('```')) {
        // Start of code block
        insideCodeBlock = true;
        codeLanguage = trimmedLine.replace(/```/, '').trim();
        currentDepth = depth;
      } else if (/^-\s+/.test(trimmedLine)) {
        // Unordered list item
        const content = trimmedLine.replace(/^-+\s+/, '').trim();
        const node = {
          type: 'li',
          content: content,
          children: [],
          depth: depth
        };

        // Adjust the listStack
        while (listStack.length > 0 && listStack[listStack.length - 1].depth >= depth) {
          listStack.pop();
        }

        if (listStack.length === 0) {
          // At top level
          const ulNode = {
            type: 'ul',
            children: [node],
            depth: depth - 1
          };
          rootNodes.push(ulNode);
          listStack.push(ulNode);
          listStack.push(node);
        } else {
          const parentNode = listStack[listStack.length - 1];
          if (parentNode.type === 'li') {
            // Need to create a new ul under this li
            const ulNode = {
              type: 'ul',
              children: [node],
              depth: depth - 1
            };
            parentNode.children.push(ulNode);
            listStack.push(ulNode);
            listStack.push(node);
          } else if (parentNode.type === 'ul') {
            parentNode.children.push(node);
            listStack.push(node);
          }
        }
      } else if (/^\d+\.\s+/.test(trimmedLine)) {
        // Ordered list item
        const content = trimmedLine.replace(/^\d+\.\s+/, '').trim();
        const node = {
          type: 'li',
          content: content,
          children: [],
          depth: depth
        };

        // Adjust the listStack
        while (listStack.length > 0 && listStack[listStack.length - 1].depth >= depth) {
          listStack.pop();
        }

        if (listStack.length === 0) {
          // At top level
          const olNode = {
            type: 'ol',
            children: [node],
            depth: depth - 1
          };
          rootNodes.push(olNode);
          listStack.push(olNode);
          listStack.push(node);
        } else {
          const parentNode = listStack[listStack.length - 1];
          if (parentNode.type === 'li') {
            // Need to create a new ol under this li
            const olNode = {
              type: 'ol',
              children: [node],
              depth: depth - 1
            };
            parentNode.children.push(olNode);
            listStack.push(olNode);
            listStack.push(node);
          } else if (parentNode.type === 'ol') {
            parentNode.children.push(node);
            listStack.push(node);
          }
        }
      } else if (trimmedLine.startsWith('http')) {
        // Before adding image node, flush buffer
        if (buffer !== '') {
          rootNodes.push({
            type: 'p',
            content: buffer.trim()
          });
          buffer = '';
        }
        // Image URL
        const imageUrl = trimmedLine;
        const node = {
          type: 'img',
          content: imageUrl,
        };
        rootNodes.push(node);
      } else {
        // Other types of lines (e.g., headings, paragraphs)
        listStack = []; // Reset the list stack

        if (/^#\s/.test(trimmedLine)) {
          if (buffer !== '') {
            rootNodes.push({
              type: 'p',
              content: buffer.trim()
            });
            buffer = '';
          }
          rootNodes.push({
            type: 'h1',
            content: trimmedLine.replace(/^#\s+/, '').trim()
          });
        } else if (/^##\s/.test(trimmedLine)) {
          if (buffer !== '') {
            rootNodes.push({
              type: 'p',
              content: buffer.trim()
            });
            buffer = '';
          }
          rootNodes.push({
            type: 'h2',
            content: trimmedLine.replace(/^##\s+/, '').trim()
          });
        } else if (/^###\s/.test(trimmedLine)) {
          if (buffer !== '') {
            rootNodes.push({
              type: 'p',
              content: buffer.trim()
            });
            buffer = '';
          }
          rootNodes.push({
            type: 'h3',
            content: trimmedLine.replace(/^###\s+/, '').trim()
          });
        } else if (/^####\s/.test(trimmedLine)) {
          if (buffer !== '') {
            rootNodes.push({
              type: 'p',
              content: buffer.trim()
            });
            buffer = '';
          }
          rootNodes.push({
            type: 'h4',
            content: trimmedLine.replace(/^####\s+/, '').trim()
          });
        } else {
          // Accumulate buffer for paragraphs
          if (trimmedLine === '' && buffer !== '') {
            // Empty line indicates end of paragraph
            rootNodes.push({
              type: 'p',
              content: buffer.trim()
            });
            buffer = '';
          } else {
            buffer += line + '\n';
          }
        }
      }
    });

    // Add any remaining buffered paragraph
    if (buffer !== '') {
      rootNodes.push({
        type: 'p',
        content: buffer.trim()
      });
    }

    return rootNodes;
  };

  const formatText = (text) => {
    const escapedText = text
      .replace(/</g, "&lt;")  // Escape "<"
      .replace(/>/g, "&gt;"); // Escape ">"

    const formattedText = escapedText
      .replace(/(\*\*\*|___)(.*?)\1/g, '<strong><em>$2</em></strong>') // Grassetto + Corsivo
      .replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>') // Grassetto
      .replace(/(\*|_)(.*?)\1/g, '<em>$2</em>') // Corsivo
      .replace(/`([^`]+)`/g, '<code class="codeText">$1</code>'); // Codice inline

    return DOMPurify.sanitize(formattedText);
  };

  const renderNodes = (nodes) => {
    return nodes.map((node, index) => {
      if (node.type === 'ul') {
        return (
          <ul key={`ul-${index}`} style={styles.ul}>
            {renderNodes(node.children)}
          </ul>
        );
      } else if (node.type === 'ol') {
        return (
          <ol key={`ol-${index}`} style={styles.ol}>
            {renderNodes(node.children)}
          </ol>
        );
      } else if (node.type === 'li') {
        return (
          <li key={`li-${index}`} style={styles.li}>
            <span dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
            {renderNodes(node.children)}
          </li>
        );
      } else if (node.type === 'h1') {
        return (
          <h1 key={`h1-${index}`} style={styles.titleMain} dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
        );
      } else if (node.type === 'h2') {
        return (
          <h2 key={`h2-${index}`} style={styles.titleSub} dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
        );
      } else if (node.type === 'h3') {
        return (
          <h3 key={`h3-${index}`} style={styles.titleSubSub} dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
        );
      } else if (node.type === 'h4') {
        return (
          <h4 key={`h4-${index}`} style={styles.titleSubSubSub} dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
        );
      } else if (node.type === 'p') {
        return (
          <p key={`p-${index}`} style={styles.paragraph} dangerouslySetInnerHTML={{ __html: formatText(node.content) }} />
        );
      } else if (node.type === 'code') {
        return (
          <div key={`code-${index}`} style={styles.codeContainer}>
            <div style={styles.codeHeader}>
              <span style={styles.languageLabel}>{node.language}</span>
              <button
                style={styles.copyButton}
                onClick={() => handleCopyClick(node.content)}
              >
                <FaCopy style={styles.copyIcon} /> {/* Icona per copiare il codice */}
              </button>
            </div>
            <pre style={styles.codeBlock}>
              <code
                className={`language-${node.language}`}
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(
                    node.content,
                    Prism.languages[node.language] || Prism.languages.plaintext,
                    node.language
                  ),
                }}
              />
            </pre>
          </div>
        );
      } else if (node.type === 'img') {
        return (
          <div key={`img-${index}`} style={styles.imageContainer}>
            <a href={node.content} target="_blank" rel="noopener noreferrer">
              <img src={node.content} alt="Immagine" style={styles.image} />
            </a>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const nodes = parseLinesToNodes(lines);
  const elements = renderNodes(nodes);

  return elements;
};

const DynamicTextRenderer = ({ text, styles }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [text]);

  return <div>{parseTextToHtml(text, styles)}</div>;
};

export default DynamicTextRenderer;
