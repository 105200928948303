import React, { useState } from 'react';
import { FaWallet } from 'react-icons/fa';

function LoginPage({ connectWallet }) {
  const [hover, setHover] = useState(false);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>OpenChatWithMe</h1>
      
      {/* Aggiunta della descrizione SEO-friendly con lo stesso stile */}
      <p style={styles.description}>
      Lets you interact with multiple AI models in one place. It’s a real-time, pay-as-you-go chat with no subscriptions or sign-ups required. Just connect your wallet and start chatting right away, paying only for what you use.
      </p>

      <button
        onClick={connectWallet}
        style={hover ? { ...styles.button, ...styles.buttonHover } : styles.button}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <FaWallet style={styles.metamaskIcon} />
      </button>

    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#0d1117',  // Stesso sfondo scuro della main page
    fontFamily: 'Futura, sans-serif',
    color: '#FFFFFF',
    padding: '20px',
  },
  title: {
    fontSize: '36px',
    marginBottom: '20px',
    color: '#3b82f6',  // Testo verde neon come nella main page
  },
  description: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '600px',
    marginBottom: '30px',
    color: '#c9d1d9',  // Testo leggermente più chiaro per contrastare lo sfondo scuro
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: 'transparent',
    color: '#3b82f6',
    border: '2px solid #3b82f6',  // Bordo verde come nella main page
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#3b82f6',  // Stesso effetto hover della main page
    color: '#000000',
  },
  metamaskIcon: {
    alignItems: 'center',
    fontSize: '20px',
  },
  buttonText: {
    color: 'inherit', // Mantenere il colore del bottone (cambia con hover)
  },
};

export default LoginPage;
