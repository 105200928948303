import React, { useState, useEffect } from 'react';

const containsCode = (text) => {
    // Rileva codice nei messaggi (controlla se il testo contiene backticks o indentazioni tipiche del codice)
    return text.includes('```');
  };


const handleCopyClick = (content) => {
navigator.clipboard.writeText(content.replace(/```/g, '')); // Copia il codice senza backticks
alert('Code copied to clipboard!');
};

const removeFirstLine = (message) => {
// Dividi il messaggio in righe
const lines = message.split('\n');
// Rimuovi la prima riga
const remainingLines = lines.slice(1);
// Unisci le righe rimanenti di nuovo in una singola stringa
return remainingLines.join('\n');
};

const selectFirstLine = (message) => {
// Dividi il messaggio in righe
const lines = message.split('\n');
// seleziona la prima riga
return lines[0];
};


const TypingMessage = ({ message }) => {
  const [displayedMessage, setDisplayedMessage] = useState(''); // Stato per il messaggio attuale
  const [index, setIndex] = useState(0); // Stato per tracciare il numero di caratteri visualizzati

  useEffect(() => {
    if (index < message.length) {
      const timeout = setTimeout(() => {
        setDisplayedMessage((prev) => prev + message[index]); // Aggiunge un carattere alla volta
        setIndex((prev) => prev + 1);
      }, 30); // Velocità di digitazione (può essere regolata)

      return () => clearTimeout(timeout); // Pulizia del timeout
    }
  }, [index, message]);

  return <div>{displayedMessage}</div>; // Mostra il messaggio gradualmente
};

export {TypingMessage, containsCode, handleCopyClick, removeFirstLine, selectFirstLine};