import React, { useState } from 'react';
import { ethers } from 'ethers';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

/*
function App() {
  const [account, setAccount] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        // Richiede l'accesso al wallet
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.send('eth_requestAccounts', []);
        setAccount(accounts[0]);
      } catch (error) {
        console.error('Errore durante la connessione a MetaMask:', error);
      }
    } else {
      alert('MetaMask non è installato. Si prega di installarlo per continuare.');
    }
  };

  return (
    <div>
      {account ? (
        <MainPage account={account} />
      ) : (
        <LoginPage connectWallet={connectWallet} />
      )}
    </div>
  );
}
*/
function App() {
  return (
    <div>
      <MainPage/>
    </div>
  );
}
export default App;
