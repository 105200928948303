const getSuccessPaymentByLang = (countryCode) => {
    const successMessages = {
      IT: 'Pagamento effettuato con successo!',   // Italiano (IT)
      US: 'Payment successfully completed!',      // Inglese (US)
      GB: 'Payment successfully completed!',      // Inglese (GB)
      CN: '付款成功完成！',                         // Cinese (CN)
      FR: 'Paiement effectué avec succès!',       // Francese (FR)
      ES: 'Pago completado con éxito!',           // Spagnolo (ES)
      DE: 'Zahlung erfolgreich abgeschlossen!',   // Tedesco (DE)
      JP: '支払いが正常に完了しました！',           // Giapponese (JP)
      AR: 'تم الدفع بنجاح!',                       // Arabo (AR)
      // Aggiungi altre lingue qui...
    };
  
    return successMessages[countryCode] || successMessages['US']; // Default in inglese
  };
  
  const getButtonTextByLang = (countryCode, isPaying) => {
    const buttonTexts = {
      IT: { paying: 'Pagamento in corso...', send: 'Come posso aiutarti?' },   // Italiano (IT)
      US: { paying: 'Payment in progress...', send: 'How can I help you?' },   // Inglese (US)
      GB: { paying: 'Payment in progress...', send: 'How can I help you?' },   // Inglese (GB)
      CN: { paying: '付款进行中...', send: '我能帮您什么?' },              // Cinese (CN)
      FR: { paying: 'Paiement en cours...', send: 'Comment puis-je vous aider?' },  // Francese (FR)
      ES: { paying: 'Pago en curso...', send: '¿Cómo puedo ayudarte?' },       // Spagnolo (ES)
      DE: { paying: 'Zahlung läuft...', send: 'Wie kann ich Ihnen helfen?' },       // Tedesco (DE)
      JP: { paying: '支払い処理中...', send: 'どのようにお手伝いできますか?' },           // Giapponese (JP)
      AR: { paying: 'جاري الدفع...', send: 'كيف يمكنني مساعدتك؟' },            // Arabo (AR)
      // Aggiungi altre lingue qui...
    };
  
    const selectedLang = buttonTexts[countryCode] || buttonTexts['US'];  // Default in inglese
    return isPaying ? selectedLang.paying : selectedLang.send;
  };
  
  // Funzione che ritorna il placeholder in base alla lingua
  const getPlaceholderByLang = (countryCode) => {
    const placeholders = {
      IT: 'Come posso aiutarti?',       // Italiano (IT)
      US: 'How can I help you?',        // Inglese (US)
      GB: 'How can I help you?',        // Inglese (GB)
      CN: '我能帮您什么?',               // Cinese (CN)
      FR: 'Comment puis-je vous aider?', // Francese (FR)
      ES: '¿Cómo puedo ayudarte?',      // Spagnolo (ES)
      DE: 'Wie kann ich Ihnen helfen?',  // Tedesco (DE)
      JP: 'どのようにお手伝いできますか?',  // Giapponese (JP)
      AR: 'كيف يمكنني مساعدتك؟',         // Arabo (AR)
      // Aggiungi altre lingue qui...
    };
  
    return placeholders[countryCode] || 'How can I help you?'; // Default in inglese
  };


  const  getLogInfoByLang = (countryCode, isPaying, isSuccessPayment, errorMessage) =>{

    var response;
    if (errorMessage) {
      return errorMessage;
          } else {
        switch (isSuccessPayment) {
            case true:
                response = getSuccessPaymentByLang(countryCode);
                return response;
            case false:
                response = getButtonTextByLang(countryCode, isPaying);
                return response;
            default:
                response = getPlaceholderByLang(countryCode);
                return response;
        }
      }
    };

  module.exports = {getLogInfoByLang, getSuccessPaymentByLang, getButtonTextByLang, getPlaceholderByLang};