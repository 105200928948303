import React from 'react';

const OptionsModelsList = () => (
  <>
    <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
    <option value="gpt-3.5-turbo-16k">GPT-3.5 Turbo 16k</option>
    <option value="gpt-3.5-turbo-instruct">GPT-3.5 Turbo Instruct</option>
    <option value="gpt-4-turbo">GPT-4 Turbo</option>
    <option value="gpt-4o">GPT-4o</option>
    <option value="gpt-4o-mini">GPT-4o Mini</option>
    <option value="chatgpt-4o-latest">ChatGPT-4o Latest</option>
    <option value="babbage-002">Babbage-002</option>
    <option value="davinci-002">Davinci-002</option>
    <option value="text-embedding-3-large">Text Embedding 3 Large</option>
    <option value="text-embedding-3-small">Text Embedding 3 Small</option>
    <option value="text-embedding-ada-002">Text Embedding Ada-002</option>
    <option value="tts-1">TTS-1</option>
    <option value="tts-1-hd">TTS-1 HD</option>
    <option value="text-moderation-latest">Text Moderation Latest</option>
    <option value="text-moderation-stable">Text Moderation Stable</option>
    <option value="dall-e-2">DALL-E 2</option>
    <option value="dall-e-3">DALL-E 3</option>
  </>
);

export default OptionsModelsList;
