import React, { useState, useEffect, useRef } from 'react';
import { ethers, NonceManager } from 'ethers';
import ContractABI from '../utils/InteractionPayment.json';
import DOMPurify from 'dompurify';
import { FaFileDownload, FaEraser, FaEthereum, FaRobot, FaPaperPlane, FaCopy, FaWallet, FaSun, FaMoon} from 'react-icons/fa';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // Tema scuro di Prism.js
import 'simplebar-react/dist/simplebar.min.css';
import OptionsModelsList from '../utils/model_list';
// Importa il supporto per Python
import 'prismjs/components/prism-python';
import DynamicTextRenderer from '../components/formatting_chat';

function MainPage() {
  const [account, setAccount] = useState(null);
  const [message, setMessage] = useState('');
  const [isPaying, setIsPaying] = useState(false);
  const [isSuccessPayment, setSuccessPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [model, setModel] = useState('gpt-3.5-turbo');
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const url_backend = process.env.REACT_APP_BACKEND_URL;
  const allowedAddresses = process.env.REACT_APP_ALLOWED_ADDRESSES;
  const [countryCode, setCountryCode] = useState('US'); // Default to US
  const [hover, setHover] = useState(false);
  const {containsCode, handleCopyClick, removeFirstLine, selectFirstLine} = require('../utils/format_response');
  const {getLogInfoByLang} = require('../utils/support_chat'); 
  const [isDarkMode, setDarkMode] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(true); 
  const [colorBackgroundDarkMode, setColorBackgroundDarkMode] = useState('#161b22');
  const [colorIconDarkMode, setColorIconDarkMode] = useState('#f7f4f0 ');
  const [colorTextDarkMode, setColorTextDarkMode] = useState('#f7f4f0 ');
  const [colorTextAreaDarkMode, setColorTextAreaDarkMode] = useState('#0d1117');
  const [colorUserBackgroundDarkMode, setColorUserBackgroundDarkMode] = useState('#f7f4f0 ');
  const [colorAssistantBackgroundDarkMode, setColorAssistantBackgroundDarkMode] = useState('#161b22');
  const [colorTextUserDarkMode, setColorTextUserDarkMode] = useState('#000');
  const [colorTextAssistantDarkMode, setColorTextAssistantDarkMode] = useState('#f7f4f0 ');
  const [borderTopBottomContainterContainerDarkMode, setBorderTopBottomContainerDarkMode] = useState('0px solid #30363d')
  const [colorTrackScrollBarContainerDarkMode, setColorTrackScrollBarContainerDarkMode] = useState('#161b22');
  const [colorThumbScrollBarContainerDarkMode, setColorThumbScrollBarContainerDarkMode] = useState('#f7f4f0 ');
  const [colorTrackScrollBarCodeDarkMode, setColorTrackScrollBarCodeDarkMode] = useState('#161b22');
  const [colorThumbScrollBarCodeDarkMode, setColorThumbScrollBarCodeDarkMode] = useState('#f7f4f0 ');
  const [showTooltip, setShowTooltip] = useState(null); // Stato per mostrare il tooltip
  const inputRef = useRef(); // Ref per il campo di input
  const [displayedText, setDisplayedText] = useState(''); // Stato per il testo attuale
  const [index, setIndex] = useState(0); // Stato per il numero di caratteri visualizzati
  const setTimoutError = 3500;
  const closeLoginPopup = () => {
  
    const isMobile = () => {
      return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    const connectWallet = async () => {
      if (window.ethereum) {
        try {
          // Richiede l'accesso al wallet
          const provider = new ethers.BrowserProvider(window.ethereum);
          const accounts = await provider.send('eth_requestAccounts', []);

          if (accounts.length > 0) {
            setAccount(accounts[0]); // Imposta l'account
            setIsLoginVisible(false); // Chiude il pop-up dopo aver impostato l'account
          }
        } catch (error) {
          console.error('Error during connection to MetaMask:', error);
        }
      } else {
        // Se l'utente è su mobile, prova a reindirizzare a MetaMask Mobile
        if (isMobile()) {
          // Reindirizza all'app MetaMask utilizzando il deep link
          window.location.href = 'https://metamask.app.link/dapp/openchatwithme.com'; // Sostituisci con il tuo dominio
        } else {
          alert('MetaMask is not installed. Please install it to continue.');
        }
      }
    };
    
    connectWallet(); // Esegue la connessione
    
  };

  const handleKeyPress = (e) => {
  // Se l'utente preme solo "Enter" senza Shift o Alt
  if (e.key === 'Enter' && !e.shiftKey && !e.altKey && message.trim()) {
    e.preventDefault();  // Previene l'andare a capo nella textarea
    handleSend();  // Esegui l'invio del messaggio
  }

  // Se l'utente preme "Shift + Enter" o "Alt + Enter", vai a capo
  if ((e.key === 'Enter' && e.shiftKey) || (e.key === 'Enter' && e.altKey)) {
    // Non facciamo nulla qui perché vogliamo che il comportamento predefinito
    // vada a capo nella textarea
  }
  };

  const handleDarkMode = ()=> {
    setDarkMode(prevState => !prevState);
    if (isDarkMode) {
      setColorBackgroundDarkMode('#161b22')
      setColorIconDarkMode('#f7f4f0 ')
      setColorTextDarkMode('#f7f4f0 ')
      setColorTextAreaDarkMode('#44484e')
      setColorUserBackgroundDarkMode('#f7f4f0 ')
      setColorTextUserDarkMode('#000')
      setColorTextAssistantDarkMode('#f7f4f0 ')
      setColorAssistantBackgroundDarkMode('#161b22')
      setBorderTopBottomContainerDarkMode('0px solid #30363d')
      setColorTrackScrollBarContainerDarkMode('#161b22')
      setColorThumbScrollBarContainerDarkMode('#f7f4f0 ')
      setColorThumbScrollBarCodeDarkMode('#f7f4f0 ')
      setColorTrackScrollBarCodeDarkMode('#161b22')
      
    } else {
      setColorBackgroundDarkMode('#f7f4f0 ')
      setColorIconDarkMode('#1e1e1e')
      setColorTextDarkMode('#0d1117')
      setColorTextAreaDarkMode('#cec5ba')
      setColorUserBackgroundDarkMode('#272822')
      setColorTextUserDarkMode('#f7f4f0 ')
      setColorTextAssistantDarkMode('#272822')
      setColorAssistantBackgroundDarkMode('#f7f4f0') //cec5ba f7f4f0 
      setBorderTopBottomContainerDarkMode('0px solid #f7f4f0 ')
      setColorTrackScrollBarContainerDarkMode('#f7f4f0 ')
      setColorThumbScrollBarContainerDarkMode('#161b22')
      setColorThumbScrollBarCodeDarkMode('#f7f4f0 ')
      setColorTrackScrollBarCodeDarkMode('#161b22')
      
    };
  };

  const handleInputChange = (e) => {
    //const input = escapeHtml(e.target.value);
    const input = `${e.target.value}`;
    const sanitizedInput = DOMPurify.sanitize(input);
    setMessage(sanitizedInput);
  };

  useEffect(() => {
    const message = `
      const OpenChatWithMe = () => {
        console.log("Lets you interact with multiple AI models in one place.");
        console.log("It’s a real-time, pay-as-you-go chat with no subscriptions or sign-ups required.");
        console.log("Just connect your wallet and start chatting right away!");
      };
    `;
    if (index < message.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + message[index]); // Aggiunge un carattere alla volta
        setIndex((prev) => prev + 1);
      }, 50); // Velocità della digitazione (50ms)

      return () => clearTimeout(timeout); // Pulisce il timeout
    };

    // Aggiunge lo stile personalizzato per la scrollbar
    var  globalStyleTag   = document.createElement('style');
    globalStyleTag.innerHTML = `
        

        p code {
          background-color: ${colorTextAreaDarkMode};
          color: ${colorTextAssistantDarkMode};
          padding: 2px 4px;
          border-radius: 4px;
        },
        .messageContent::hover {
          background-color: yellow; /* Il colore di sfondo al passaggio del mouse */
        }

        .defaultPlaceholder::placeholder {
            color: grey;
          }

        .customPlaceholder::placeholder {
          font-weight: bold;
          color: #CC0000; /* Colore per il placeholder personalizzato */
        }
        .paymentProcessPlaceholder::placeholder {
          font-style: italic;
          color: #3b6978; /* Colore per il placeholder personalizzato */
        }
        /* Applicare lo stile della scrollbar a livello globale */
        *::-webkit-scrollbar {
          width: 10px;
          height: 8px;
        }

        *::-webkit-scrollbar-track {
          background: ${colorTrackScrollBarContainerDarkMode};
          border-radius: 7px;
        }

        *::-webkit-scrollbar-thumb {
          background: ${colorThumbScrollBarContainerDarkMode};
          border-radius: 7px;
        }

        /* Nascondere le frecce */
        *::-webkit-scrollbar-button {
          display: none;
        }

        /* Per browser non Webkit */
        * {
          scrollbar-color: ${colorThumbScrollBarContainerDarkMode} ${colorTrackScrollBarContainerDarkMode};
          scrollbar-width: thin;
        }
      `;
    document.head.appendChild(globalStyleTag);

    // Pulizia dell'effetto quando il componente viene smontato
    return () => {
      document.head.removeChild(globalStyleTag);
    };

    
  }, [index, colorThumbScrollBarContainerDarkMode, colorTrackScrollBarContainerDarkMode, colorTextAreaDarkMode, colorTextAssistantDarkMode]);
  

  const styles = {
    container: {
      backgroundColor: colorBackgroundDarkMode,
      color: '#FFFFFF',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Futura, sans-serif',
      width: '100vw',  // Assicurati che il contenitore occupi il 100% della larghezza
      maxWidth: '100vw', // Evita che superi la larghezza dello schermo
      overflowX: 'hidden', // Evita lo scrolling orizzontale
    },
    topBar: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '5px',
      backgroundColor: colorBackgroundDarkMode,
      borderRadius: '15px',
      border: borderTopBottomContainterContainerDarkMode,
      marginTop: '5px',
      marginBottom: '10px',
    },
    leftSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',  // Spinge l'icona a destra
    },
    centerSection: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colorBackgroundDarkMode,
      justifyContent: 'center',
    },
    rightSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',  // Spinge l'icona a destra
    },
    downloadButton: {
      backgroundColor: colorBackgroundDarkMode, // Usa lo stesso schema di colori
      color: colorIconDarkMode,
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      margin: '10px',
      fontSize: '24px',
    },
    eraseIcon: {
      fontSize: '24px',
      color: colorIconDarkMode, // Colore blu per l'icona del robot
    },
    select: {
      backgroundColor: colorTextAreaDarkMode,
      color: colorTextDarkMode,
      border: '0px solid #30363d',
      borderRadius: '5px',
    },
    chatBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
      height: '80vh',
      overflowY: 'auto',
      backgroundColor: colorBackgroundDarkMode,
      padding: '10px',
      borderRadius: '5px',
      border: '0px solid #30363d',
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '5px',
      backgroundColor: colorBackgroundDarkMode,
      borderRadius: '15px',
      border: borderTopBottomContainterContainerDarkMode,
      marginTop: '5px',
      marginBottom: '10px',
      maxHeight: '90x', // Altezza massima
      boxSizing: 'border-box',
    },
    textarea: {
      margin: 'auto',
      flexGrow: 1,
      padding: '10px',  // Aumenta il padding superiore per centrare verticalmente
      fontSize: '15px',
      backgroundColor: colorTextAreaDarkMode,
      color: colorTextDarkMode,
      border: 'none',
      borderRadius: '15px',
      marginRight: '10px',
      outline: 'none',
      resize: 'none', // Impedisce il ridimensionamento manuale
      overflowY: 'auto', // Mostra la scrollbar quando necessario
      maxHeight: '90px', // Altezza massima
      minHeight: '40px',  // Altezza minima
      lineHeight: '1.5',
      fontFamily: 'Futura, sans-serif',
      width: '100%', // Imposta la larghezza al 100%
    },    
    input: {
      flexGrow: 1,
      padding: '10px',
      fontSize: '16px',
      backgroundColor: colorTextAreaDarkMode,
      color: colorTextDarkMode,
      border: 'none',
      borderRadius: '30px',
      marginRight: '10px',
      outline: 'none',
    },
    darkButton: {
      alignSelf: 'flex-end',
      backgroundColor: colorBackgroundDarkMode,//'#3b82f6', // Colore blu per il bottone
      color: colorIconDarkMode,//'#e5dbcf',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      padding: '10px',
    },
    darkIcon: {
      marginTop: '2px',
      alignSelf: 'flex-end',
      fontSize: '22px', // Dimensione dell'icona di invio
    },
    sendButton: {
      backgroundColor: colorBackgroundDarkMode,//'#3b82f6', // Colore blu per il bottone
      color: colorIconDarkMode,//'#e5dbcf',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      padding: '10px',
    },
    sendIcon: {
      fontSize: '20px', // Dimensione dell'icona di invio
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: colorUserBackgroundDarkMode,
      color: colorTextUserDarkMode,
      padding: '10px',
      borderRadius: '10px',
      marginBottom: '10px',
      maxWidth: '40%',
      textAlign: 'center',
      fontSize: '15px',
    },
    assistantMessage: {
      alignSelf: 'flex-start',
      backgroundColor: colorAssistantBackgroundDarkMode,
      color: colorTextAssistantDarkMode,
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '5px',
      maxWidth: '90%',
      textAlign: 'left',
      fontSize: '15px',
      cursor: 'pointer',
      position: 'relative',
    },
    codeContainer: {
      backgroundColor: '#1e1e1e', // Sfondo scuro per i blocchi di codice
      borderRadius: '5px',
      marginBottom: '10px',
      padding: '5px',
      position: 'relative',
      maxWidth: '90%',
    },
    codeHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#272822',
      padding: '5px 10px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      color: '#fff',
    },
    languageLabel: {
      fontSize: '12px',
      color: '#d4d4d4',
      padding: '2px 5px',
      backgroundColor: '#272822',
      borderRadius: '3px',
    },
    copyButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
    copyIcon: {
      fontSize: '18px',
      color: '#d4d4d4',
    },
    codeBlock: {
      backgroundColor: '#1e1e1e', // Sfondo scuro per i blocchi di codice
      color: '#dcdcdc',
      padding: '10px',
      borderRadius: '5px',
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
    },
    imageContainer: {
      alignSelf: 'center',
      backgroundColor: '#f7f4f0 ',
      color: '#000',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '5px',
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
      overflow: 'hidden',
    },
    image: {
      maxWidth: '100%',   // Dimensiona l'immagine per adattarla al contenitore
      height: 'auto', 
      width:'100%',    // Mantieni il rapporto di aspetto
      borderRadius: '5px',
      cursor: 'pointer',  // Imposta il cursore a "pointer" per indicare che è cliccabile
      objectFit: 'cover',
      display: 'block',
      margin: '0 auto',
    },
    popupOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    popup: {
      backgroundColor: '#1f2937',
      color: '#FFFFFF',
      padding: '40px',
      borderRadius: '10px',
      textAlign: 'center',
      width: '400px',
    },
    loginButton: {
        alignItems: 'center',
        padding: '15px 30px',
        fontSize: '18px',
        backgroundColor: 'transparent',
        color: colorIconDarkMode,
        border: `2px solid ${colorIconDarkMode}`,  // Bordo verde come nella main page
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, color 0.3s',
    },
    buttonHover: {
      backgroundColor: colorIconDarkMode,  // Stesso effetto hover della main page
      color: '#000000',
    },
    metamaskIcon: {
      alignItems: 'center',
      fontSize: '20px',
    },
    titleMain: {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    titleSub: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    titleSubSub: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleSubSubSub: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    paragraph: {
      fontSize: '1em',
      lineHeight: '1.5em',
    },
    list: {
      marginLeft: '20px', // Margine per gli elenchi
    },
    listItem: {
      fontSize: '1em',
    },
    tooltip: {
      position: 'absolute',
      backgroundColor: colorTextAreaDarkMode,
      color: colorTextAssistantDarkMode,
      padding: '5px',
      borderRadius: '100px',
      top: '-5px',
      left: '-0',
      zIndex: 1,
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    error: {
      color: 'red',
    },
    };
    
  
  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleCopyToInput = (content) => {
    const prefix = "continues to talk about: \n\n";
    setMessage(`${prefix}${content}`); // Copia il testo del div nel campo di input
    if (inputRef.current) {
      inputRef.current.focus(); // Focus sull'input per una migliore UX
    }
  };

  const handleMouseEnter = (event, index) => {
    setShowTooltip(index); // Mostra il tooltip al passaggio del mouse
  };

  const handleMouseLeave = (event) => {
    setShowTooltip(null); // Nasconde il tooltip quando il mouse esce
  };


  const handleSend = async () => {
    if (!message) return;
    setSuccessPayment(false);
    setIsPaying(true);
    setErrorMessage('');

    const chatMessages = [...chatHistory, { role: 'user', content: message }];

    const concatenatedMessages = chatMessages
    .map(chatMessages => chatMessages.content)  // Estrai il contenuto di ogni messaggio
    .join('\n');  // Unisci tutti i messaggi con una nuova riga (puoi usare uno spazio o altro se preferisci)

    setChatHistory(prevHistory => [
      ...prevHistory,
      { role: 'user', content: message }
    ]);
    setMessage(''); // Svuota il campo di input

    try {
      console.log("Check whether MetaMask is installed...");
      if (!window.ethereum) {
        throw new Error('MetaMask is not installed. Please install it to continue.');
      }

      console.log("Connection to MetaMask...");
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const userAddress = await signer.getAddress();      

      if (allowedAddresses.includes(userAddress)) {
        console.log("User address is in the allowed list, skipping payment.");
        setSuccessPayment(true);
      } else {
        const contract = new ethers.Contract(contractAddress, ContractABI.abi, signer);
        console.log("Checking the available balance...");
        const costPerInteraction = ethers.parseEther('0.01');  // 0.01 ETH come BigInt
        const balance = await provider.getBalance(signer.address);  // Restituisce BigInt in ethers.js v6

        if (balance < costPerInteraction) {
          console.log("Insufficient balance.");
          setErrorMessage('Insufficient balance to make payment.');
          setIsPaying(false);
          setTimeout(() => {
            setErrorMessage(''); // Resetta il messaggio di errore
          }, setTimoutError); // Imposta il timeout di 5 secondi
          return;
        }

        console.log("Sending the transaction to MetaMask...");
        const tx = await contract.payForInteraction({ value: costPerInteraction });
        console.log('Transaction sent:', tx);

        await tx.wait();
        console.log('Transaction confirmed:', tx);

        setSuccessPayment(true);
      }
      // Chiamata al back-end con gestione degli errori di rete
      try {
        console.log("Sending the request to the back-end...");
        const response = await fetchWithTimeout(`${url_backend}/api/ask`, {
          method: 'POST',
          body: JSON.stringify({ concatenatedMessages, model, userAddress }),
          headers: { 'Content-Type': 'application/json' },
        }, 100000); 

        if (!response.ok) {
          const retryModel = "gpt-3.5-turbo"
          console.error(`Error during request first attempt with model: ${model}`);
          console.log(`Switching to ${retryModel} for the second attempt...`)
          const response = await fetchWithTimeout(`${url_backend}/api/ask`, {
            method: 'POST',
            body: JSON.stringify({ concatenatedMessages, retryModel , userAddress }),
            headers: { 'Content-Type': 'application/json' },
          }, 100000); 
          return await response
        }

        const data = await response.json();
        console.log("Response received from the back-end:", data.response);

        // Dividi la risposta in più messaggi utilizzando i capoversi
        const responseParts = [];
        let insideCodeBlock = false;
        let buffer = '';
        
        data.response.split('\n').forEach((line) => {
          // Rileva i blocchi di codice con ```
          if (line.trim().startsWith('```')) {
            insideCodeBlock = !insideCodeBlock; // Inverti lo stato di insideCodeBlock
            buffer += line + '\n'; // Aggiungi la riga del blocco di codice al buffer
          } else if (insideCodeBlock) {
            // Se siamo dentro un blocco di codice, aggiungi semplicemente le righe al buffer
            buffer += line + '\n';
          } else {
            // Se siamo fuori da un blocco di codice, controlla i capoversi
            if (line.trim() === '') {
              // Quando troviamo una riga vuota, significa un nuovo paragrafo
              if (buffer.trim() !== '') {
                responseParts.push(buffer.trim()); // Aggiungi il buffer a responseParts
              }
              buffer = ''; // Resetta il buffer
            } else {
              buffer += line + '\n'; // Aggiungi le righe normali al buffer
            }
          }
        }
        );

        // Aggiungi l'ultimo buffer se non è vuoto
        if (buffer.trim() !== '') {
          responseParts.push(buffer.trim());
        }
        
        // Mostra i messaggi uno alla volta con un ritardo
        for (let i = 0; i < responseParts.length; i++) {
          setTimeout(() => {
            setChatHistory(prevHistory => [
              ...prevHistory,
              { role: 'assistant', content: responseParts[i] }
            ]);
          }, i * 2000); // Aggiungi un ritardo di 2 secondi tra ogni messaggio
        }

      } catch (networkError) {
        if (networkError.name === 'AbortError') {
          setErrorMessage('The request took too long and was interrupted.');
          setTimeout(() => {
            setErrorMessage(''); // Resetta il messaggio di errore
          }, setTimoutError); // Imposta il timeout di 5 secondi
        } else {
          console.error('Network error:', networkError);
          setErrorMessage('A network error has occurred. Please try again.');
          setTimeout(() => {
            setErrorMessage(''); // Resetta il messaggio di errore
          }, setTimoutError); // Imposta il timeout di 5 secondi
        }
      }

    } catch (error) {
      console.error('Error during payment:', error);

      if (error.code === 'ACTION_REJECTED') {
        setErrorMessage('Transaction rejected by the user.');
        setTimeout(() => {
          setErrorMessage(''); // Resetta il messaggio di errore
        }, setTimoutError); // Imposta il timeout di 5 secondi
      } else if (error.message === 'MetaMask is not installed. Please install it to continue.') {
        setErrorMessage(error.message);
        setTimeout(() => {
          setErrorMessage(''); // Resetta il messaggio di errore
        }, setTimoutError); // Imposta il timeout di 5 secondi
      } else {
        setErrorMessage('An error occurred during payment.');
        setTimeout(() => {
          setErrorMessage(''); // Resetta il messaggio di errore
        }, setTimoutError); // Imposta il timeout di 5 secondi
      }
    } finally {
      setIsPaying(false);
      setSuccessPayment(false);
    }
  };

  const escapeHtml = (text) => {
    return text
      .replace(/&/g, "&amp;") // Ampersand
      .replace(/</g, "&lt;")  // Less than
      .replace(/>/g, "&gt;")  // Greater than
      .replace(/"/g, "&quot;") // Double quote
      .replace(/'/g, "&#039;") // Single quote
      .replace(/`/g, "&#x60;"); // Backtick
  };
  
  // Funzione per gestire il timeout delle richieste
  const fetchWithTimeout = (url, options, timeout = 10000) => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchPromise = fetch(url, { ...options, signal });
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    return fetchPromise.finally(() => clearTimeout(timeoutId));
  };

  const downloadChat = () => {
    let chatContent = '';
  
    chatHistory.forEach(entry => {
      if (entry.role === 'user') {
        chatContent += `User: ${entry.content}\n\n`; // Formatta i messaggi dell'utente
      } else if (entry.role === 'assistant') {
        chatContent += `Assistant: ${entry.content}\n\n`; // Formatta i messaggi dell'assistente
      }
    });
  
    // Crea un Blob con il contenuto della chat
    const blob = new Blob([chatContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chat-history.txt'; // Nome del file che verrà scaricato
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClearChat = () => {
    const confirmed = window.confirm("Delete chat?");
    if (confirmed) {
      setChatHistory([]); // Svuota il contenuto del div
    }
  };

  const formatPopUpText = (text) => {
    // Funzione per formattare il testo come codice di programmazione con colori diversi
    return text.split('\n').map((line, idx) => (
      <div key={idx}>
        {line.split(' ').map((word, i) => {
          if (word.includes('const') || word.includes('console.log')) {
            return <span key={i} style={{ color: '#00d8ff' }}>{word} </span>; // Parole chiave in azzurro
          } else if (word.includes('OpenChatWithMe')) {
            return <span key={i} style={{ color: '#f8c555' }}>{word} </span>; // Funzione in arancione
          } else {
            return <span key={i} style={{ color: '#fff' }}>{word} </span>; // Parole normali in bianco
          }
        })}
      </div>
    ));
  };

  const formatMessage = (message) => {
    if (message.startsWith("http")) {
      const imageUrl = message.trim();
      return (
        <div style={styles.imageContainer}>
          <a href={imageUrl} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt="Immagine generata" style={styles.image} />
          </a>
        </div>
      );
    }
    if (containsCode(message)) {
      const codeContent = removeFirstLine(message.replace(/```/g, ''));
      const languageProgram = selectFirstLine(message.replace(/```/g, ''));
      return (
        <div style={styles.codeContainer}>
          <div style={styles.codeHeader}>
            <span style={styles.languageLabel}>{languageProgram}</span>
            <button style={styles.copyButton} onClick={() => handleCopyClick(codeContent)}>
              <FaCopy style={styles.copyIcon} /> {/* Icona del copia codice */}
            </button>
          </div>
          <pre style={styles.codeBlock}>
            <code className={`language-${languageProgram}`}>{codeContent}</code> {/* Prism applica la sintassi */}
          </pre>
        </div>
      );
    } else {
      const lines = message.split('\n'); // Divide il messaggio in righe

      return lines.map((line, index) => {
        // Funzione che formatta il grassetto per il testo racchiuso tra **
        const formatBoldText = (text) => {
          const parts = text.split(/(.*?)\*\*/g); // Divide il testo usando il grassetto
          return parts.map((part, i) =>
            i % 2 === 1 ? <strong key={i}>{part}</strong> : part
          );
        };
    
        // Controlla se la riga è un titolo di livello 3 (###)
        if (line.startsWith('### ')) {
          return (
            <h2 key={index} style={styles.titleMain}>
              {formatBoldText(line.replace('### ', ''))}
            </h2>
          );
        }
    
        // Controlla se la riga è un sottotitolo di livello 4 (####)
        if (line.startsWith('#### ')) {
          return (
            <h3 key={index} style={styles.titleSub}>
              {formatBoldText(line.replace('#### ', ''))}
            </h3>
          );
        }
    
        // Controlla se la riga è una lista numerata
        if (/^\d+\.\s/.test(line)) {
          return (
            <p key={index} style={styles.titleSubSub}>
              {formatBoldText(line)} {/* Numero rimosso e testo con grassetto */}
            </p>
          );
        }
    
        // Controlla se la riga è un elenco puntato
        if (line.startsWith('- ')) {
          return (
            <p key={index} style={styles.paragraph}>
              {formatBoldText(line)} {/* Testo con grassetto */}
            </p>
          );
        }
    
        // Altrimenti, restituisci il paragrafo
        if (line.trim() !== '') {
          return (
            <p key={index} style={styles.paragraph}>
              {formatBoldText(line)}
            </p>
          );
        }
    
        // Se la linea è vuota, non restituire nulla (spazi vuoti)
        return null;
      });
    }
  };
    
  return (
    <div style={styles.container}>
      <div style={styles.topBar}>
        <div style={styles.leftSection}>
          <button onClick={downloadChat} style={styles.downloadButton}>
            <FaFileDownload style={styles.eraseIcon} /> {/* Icona della gomma */}
          </button>
        </div>
        
        <div style={styles.centerSection}>
          <button onClick={handleClearChat} style={styles.darkButton}>
            <FaEraser style={styles.eraseIcon} /> {/* Icona della gomma */}
          </button>
          <select value={model} onChange={handleModelChange} style={styles.select}>
            <OptionsModelsList />  
          </select>
        </div>

        <div style={styles.rightSection}>
          <button onClick={handleDarkMode} style={styles.darkButton}>
            {isDarkMode ?  <FaSun style={styles.darkIcon} /> : <FaMoon style={styles.darkIcon} /> }
          </button>
        </div>
      </div>
      {isLoginVisible && (
          <div style={styles.popupOverlay}>
            <div style={styles.popup}>
              <h1 style={{ color: colorIconDarkMode, marginBottom: '20px' }}>OpenChatWithMe</h1>
              <p>
              {formatPopUpText(displayedText)} {/* Mostra il testo formattato */}
              </p>
              <button style={hover ? { ...styles.loginButton, ...styles.buttonHover } : styles.loginButton} onClick={closeLoginPopup}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}>
                <FaWallet style={styles.metamaskIcon} />
              </button>
            </div>
          </div>
        )}
      <div className="chatBox" style={styles.chatBox}>
          {chatHistory.map((entry, index) => (
            <div
              key={index}
              style={
                entry.role === 'user'
                  ? styles.userMessage
                  : styles.assistantMessage
              }
              onClick={entry.role !== 'user' ? () => handleCopyToInput(entry.content) : undefined} // Copia il contenuto del messaggio nel campo input
              onMouseEnter={entry.role !== 'user' ? (e) => handleMouseEnter(e, index) : undefined} // Mostra il tooltip al passaggio del mouse
              onMouseLeave={entry.role !== 'user' ? handleMouseLeave : undefined} // Nasconde il tooltip quando il mouse esce    
            >
              <DynamicTextRenderer text={entry.content} styles={styles} /> {/* Formatta il messaggio */}
              {/* Mostra il tooltip se necessario */}
              {showTooltip === index && (
                <span style={styles.tooltip}>Ask me</span>
              )}
            </div>
          ))}
      </div>

      <div style={styles.inputContainer}>
          <textarea
          placeholder={getLogInfoByLang(countryCode, isPaying, isSuccessPayment, errorMessage)}
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={styles.textarea}
          disabled={isPaying}

          className={errorMessage !== '' ? 'customPlaceholder' : getLogInfoByLang(countryCode, isPaying, isSuccessPayment, errorMessage) === 'How can I help you?' ?  'defaultPlaceholder' : 'paymentProcessPlaceholder'} 
          />
          <button
            onClick={handleSend}
            disabled={isPaying || !message}
            style={styles.sendButton}
          >
            <FaPaperPlane style={styles.sendIcon} /> {/* Icona di aeroplano di carta */}
          </button>
      </div>
    </div>
  );
  };

export default MainPage;
